html {
  font-family: sans-serif;
  line-height: 1.15;

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #000;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 0.4em;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}
ul li,
li {
  list-style: none !important;
}
.dropdown-large {
  padding: 1rem;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  width: 600px !important;
}

/* ============ deskfa view ============ */
@media all and (min-width: 992px) {
  .dropdown-large {
    min-width: 500px;
  }
}
/* ============ deskfa view .end// ============ */

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.landing {
  background-color: #0029FF;
}
.text-primary_1 {
  color: #0029FF;
}
/* @media (min-width: 992px){
.container {
    max-width: 960px;
}
}
@media (min-width: 768px){
.container {
    max-width: 720px;
}
}
@media (min-width: 576px){
.container {
    max-width: 540px;
}
} */
.InputFields{
  padding: 3px;
}
.InputFields input,.InputFields select{
  padding: 8px;
  border-radius: 3px;
  width: 100%;
  border: none;
  outline: none;
  background-color: white;
}
.SubmitAndReset{
  display: flex;
  justify-content: space-evenly;
}
.SubmitAndReset input{
  padding: 6px 30px;
  border-radius: 3px;
  margin-top: 20px;
  color: rgb(24, 21, 21);
  background-color: #ffcc00;
  font-weight: 500;
  cursor: pointer;
  font-size: 17px;
}

.SubmitAndReset input:hover{
  box-shadow: 0 0 3px 2px white;
}
.InputFields label{
  font-weight: 400;
  font-size: 17px;
}
.InputFields input:focus,.InputFields select:focus{
  /* border: 1px solid rgb(74, 149, 248); */
  box-shadow: 0 0 2px 3px #ffcc00 ;
  transition: 0.3s;
}
img {
  width: 100%;
}
.fw-900 {
  font-weight: 900;
}
.fw-700 {
  font-weight: 700;
}
.lead {
  font-size: 18px;
  line-height: 30px;
  padding-right: 100px;
  font-weight: 400;
}
.opdrop {
  color: #000;
}

.border-left {
  padding-left: 35px;
  border-left: solid 5px #ffcc00 !important;
}
.form-group {
  padding: 10px 0px;
}

.display-4 {
  line-height: 1.5;
}
.display-6 {
  font-size: 18px !important;
  padding-top: 15px;
}
.display_5 {
  font-size: 45px;
  font-weight: 900;
  font-family: sans-serif;
  margin-bottom: 50px;
}
.display_e {
  font-size: 30px;
  font-weight: 900;
  font-family: sans-serif;
  margin-bottom: 50px;
}

.educationAs {
  font-size: 25px;
  font-weight: 500;
  font-family: sans-serif;
  margin-bottom: 50px;
}
.btn {
  padding: 13px 30px;
  /* text-transform: uppercase; */
}

.btn-primary {
  background-color: #0029ff !important;
  color: #fff;
}
.btn-secondary {
  background-color: rgba(0, 41, 255, 0.1) !important;
  color: #0029ff;
  border: none !important;
}
.btn-secondary:hover {
  color: #0029ff;
}
.btn-white {
  background-color: #fff;
  color: #000;
}
.text-primary {
  color: #0029ff;
}
.icon {
  width: 60px;
}

.dt-card {
  padding: 30px 10px 30px 20px;

  box-shadow: 0 4px 15px 0 rgb(7 42 68 / 10%);

  background: #fff;
  border-radius: 8px;
  border: 2px solid #f0f3f8;
}
.section {
  padding: 50px;
  padding-bottom: 50px;
  /* padding: 30px; */
    /* padding-bottom: 98px; */
}
.blog-card img {
  width: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.blog-description {
  padding: 25px 25px 15px;
}
.blog-card {
  z-index: 9;
  cursor: pointer;
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 3px 12px rgb(98 145 247 / 10%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.bold {
  font-weight: 700;
  text-transform: uppercase;
}

.blog-cards a {
  text-decoration: none !important;
}

.blog-cards img {
  height: 180px;
  object-fit: cover;
}

.blog-cards p {
  color: #000;
}

#phone {
  font-size: 15px;
  font-weight: 600;
}
.footerIcon {
  font-size: 15px;
}
.social-links li a {
  height: 46px;
  width: 46px;
  background: #161718;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
  display: inline-block;
  margin-left: 10px;
}
.social-links {
  float: right;
}

.social-links li {
  float: left;
  list-style-type: none;
}

.primary-bg {
  /* background-color: #0029ff; */
  background-color: #142c51;
}
.secondary-bg {
  background-color: #f3f6ff;
}

.text-yellow {
  color: #ffcc00;
}
.landing .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
}

.form-control {
  color: #fff !important;
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #ffcc00;
  color: #000;
  cursor: pointer;
  /* padding: 15px 24px; */
  padding: 10px 14px;
  border-radius: 4px;
  border-radius: 50%;
}

#myBtn:hover {
  background-color: #000;
  color: #fff;
}
.footerContainer a.tel {
  text-decoration: none !important;
  color: #fff;
}

#demo {
  /* background: linear-gradient(112deg, #ffffff 50%, antiquewhite 50%); */
  max-width: 900px;
  margin: auto;
}

.carousel-caption {
  position: initial;
  z-index: 10;
  padding: 5rem 8rem;
  color: #000;
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
}

@media (max-width: 767px) {
  .carousel-caption {
    position: initial;
    z-index: 10;
    padding: 3rem 2rem;
    color: #000;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .testi_l{
    line-height: 44px !important;
  }
  .section{
    padding: 20px 5px 50px 5px;
  }
  .lead{
    margin-bottom:20px !important;
    padding-right: unset;
  }
}

.carousel-caption img {
  width: 6rem;
  border-radius: 5rem;
  margin: 2rem;
}

.quotes {
  width: 50px;
  float: left;
  margin-left: 150px;
}

@media (max-width: 767px) {
  .carousel-caption img {
    width: 4rem;
    border-radius: 4rem;
    margin: 1rem;
  }
}

#image-caption {
  font-size: 1.5rem;
  margin: 0.5rem;
  padding-bottom: 10px;
  color: #0029ff;
}

@media (max-width: 767px) {
  #image-caption {
    font-style: normal;
    font-size: 1.5rem;
    margin: 0.5rem;
  }
}

.testimonials i {
  background-color: rgba(0, 41, 255, 0.1);
  padding: 1.4rem;
  color: #0029ff;
  border-radius: 50%;
}

@media (max-width: 767px) {
  i {
    padding: 0.8rem;
  }
}

.carousel-control-prev {
  justify-content: flex-start;
}

.carousel-control-next {
  justify-content: flex-end;
}

.carousel-control-prev,
.carousel-control-next {
  transition: none;
  opacity: unset;
}

.req_btn {
  font-weight: 500;
}

.about_l {
  font-size: 35px;
}

.testi_l {
  font-size: 45px;
}

.testi_ld {
  font-size: 30px;
  margin-top: 10px;
}

.test_p {
  font-weight: 400;
}
