input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.postContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10%;
}
.postContainer .innerPostContent h4 {
  font-size: 28px;
  color: black;
  margin-top: 25px;
}

.postContainer .innerPostContent img {
  border-radius: 5px;
}
.postContainer .innerPostContent video {
  object-fit: fill;
  border-radius: 5px;
}
.postContainer .innerPostContent p p {
  /* font-size: 20px; */
  color: black;
  /* margin-top: 13px; */
}
.home5 {
  display: flex;
}
iframe {
  margin-top: 15px;
}
footer {
  background-color: black;
}
footer .footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  color: white;
  max-width: 1140px;
  margin: auto;
}
footer .footerContainer .row1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 30px;
  align-items: baseline;
  /* margin:40px auto; */
}
footer .footerContainer .row1 span {
  display: flex;
  align-items: baseline;
  flex-direction: row;
}
footer .footerContainer .row1 h3 {
  font-weight: 600;
  margin-right: 12px;
}
footer .footerContainer .row1 h4 {
  font-size: 23px;
  font-weight: 700;
}
footer .footerContainer .row2 a {
  text-decoration: none;
  color: white;
}
footer .footerContainer .row2 {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
footer .footerContainer .row2 a {
  font-size: 16px;
}
.socialLinks {
  display: flex;
  align-items: center;
}
#phone {
  font-size: 15px;
  font-weight: 400;
}
.footerIcon {
  font-size: 15px;
}
.Leadercontent #hrLine {
  width: 60px;
  margin: 10px auto;
}
/* .leadership{
    margin: 0 auto;
    justify-content: center; 
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    width: max-content;
    border: 1px solid;
    height: max-content;
} */
.Leadercontent {
  width: 32.8%;
  height: 460px;
  border-radius: unset;
  display: block;
  /* margin: 0 6px 6px auto; */
  margin-left: 6px;
  margin-top: 6px;
  margin-bottom: unset;
  align-items: center;
}
.Leadercontent #desc {
  overflow: hidden;
  /* height: 108px; */
  text-align: center;
  font-size: 13px;
  color: rgb(85, 85, 85);
  font-weight: 300;
  line-height: 20px;
}
#LeaderHeading {
  font-size: 25px;
}
.Leadercontent #leaderImage {
  width: 55%;
  height: 50%;
}
.Leadercontent:hover {
  box-shadow: 0px 6px 23px 3px rgb(148, 148, 148);
  transition: 0.6s;
  z-index: 199;
}
#Designation {
  color: black;
  font-weight: 500;
  font-size: 13px;
}
.videoContainer {
  width: 45%;
  /* justify-content: center; */
  display: flex;
  align-items: center;
  margin-left: 5%;
}
.videoContainer iframe {
  height: 240px !important;
  margin-top: 6px;
  border-radius: 5px;
  border: 4px solid black;
}
.videoContainer .video {
  height: 260px !important;
  border-radius: 5px;
}
/* .videoContainer .video {
    height: 250px !important;
    width: 100%;
    border-radius: 8px;
    user-select: contain;
} */
#HomeImg {
  width: 50%;
  border-radius: 8px;
  height: 310px;
}
#SubmenuImg {
  width: 42px;
  height: 42px;
}
#aboutUsImg {
  width: 18%;
  height: 60px;
  filter: hue-rotate(134deg);
}
.ArrowLeftIcon {
  align-self: center;
  /* border: 1px solid; */
  font-size: 30px;
  padding: 4px 10px 4px 10px;
  /* background-color: rgb(70, 128, 253); */
  border-radius: 50%;
  color: white;
  opacity: 0.5;
  /* color: white; */
}
.ArrowIcon {
  align-self: center;
  /* border: 1px solid; */
  font-size: 30px;
  padding: 4px 10px 4px 10px;
  /* background-color: rgb(70, 128, 253); */
  border-radius: 50%;
  color: white;
}
.subMenu {
  padding: 13px !important;
  /* font-weight: bold; */
}
#liOfMenu span {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
#liOfMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}
.submenus {
  color: white !important;
  text-decoration: none;
  margin-left: 20px;
  font-weight: 400;
}
.subMenuIcon {
  font-size: 6px;
  margin-right: 5px;
}
#pTagSubmenu {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.revolution-bg {
  padding: 30px 0px;
}
/* .PrivateViewSection{
    margin-top: 30px;
}
.main-head{
    margin-bottom: 30px;
} */
.blog-img {
  width: auto;
  height: 187px !important;
  border-radius: 5px;
  margin-bottom: 0px;
  object-fit: contain;
}
.blog-img img {
  height: 187px !important;
  width: max-content !important;
}
#ArticlesAuthorName {
  margin: 0;
  padding: 0;
}
#ArticlesAuthorNameSingle {
  color: var(--highlighting-color);
  text-decoration: underline;
  text-underline-offset: 2px;
}

.SingleBlogAuthorNameContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.SingleBlogAuthorName {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
}
.carousel {
  text-align: center;
  display: flex;
  flex-direction: row;
}
.leftArrowMedia {
  margin-right: 25px !important;
}
.rightArrowMedia {
  margin-left: 25px !important;
}
.fadeArrow {
  background: #465798;
  opacity: 0.4;
  transition: 0.4s;
}
.revolution-head h1 {
  margin-bottom: 25px;
}
.imgOfMedia {
  display: flex;
  /* border: 2px solid; */
  flex-direction: row;
  margin: 0px 5px;
  width: 200px;
  height: 75px;
  background-color: white;
  z-index: 100;
  padding: 15px;
}
.imgOfMedia:hover {
  background: #dce6ff;
}

.mediaArrow {
  border: 1px solid;
  padding: 4px 10px 6px 10px;
  color: white;
  background-color: #0029ff;
}

@media (max-width: 991px) {
  .media-resence .carousel.carousel-slider {
    margin: unset;
    width: 100% !important;
  }
  .imgOfMedia {
    margin: 3px auto;
    width: 250px;
  }
  .leftArrowMedia {
    margin-left: 0px !important;
  }
  .rightArrowMedia {
    margin-right: 0px !important;
  }
  .layout {
    padding-top: 65px;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    overflow: hidden;
  }
  .videoContainer {
    width: 100%;
    margin-left: unset;
  }
  .deep-cms p {
    overflow-wrap: break-word;
  }
  .deep-space p {
    overflow-wrap: break-word;
  }

  iframe {
    width: 100% !important;
    padding-left: unset !important;
    padding-right: unset !important;
    float: unset !important;
    height: 200px !important;
  }
  .video {
    height: 200px;
    object-fit: fill;
  }
  .scrolls-space {
    border-bottom: unset;
  }
  footer .footerContainer {
    /* justify-content: unset !important; */
    /* margin: 25px 10px 6px 10px; */
    height: unset !important;
    padding: 16px;
    flex-direction: column;
  }
  footer .footerContainer .row1 {
    flex-direction: column !important;
    align-items: center !important;
  }
  footer .footerContainer .row1 span {
    margin: 4px !important;
  }
  .carousel-item {
    padding: 0 26px !important;
  }
  footer .footerContainer .row2 {
    margin: 10px 0 0 0 !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
  }
  footer .footerContainer .row2 a {
    margin: 10px;
  }
  .footerCopy {
    display: flex;
    justify-content: center;
  }
  .Leadercontent {
    width: unset;
    margin-bottom: 9px;
    margin-right: 0px;
    margin-left: unset;
    margin-top: unset;
  }
  .row {
    flex-direction: column;
  }
  .postContainer {
    width: 100%;
  }
  .postContainer .innerPostContent img {
    width: 100% !important;
    height: 220px !important;
    padding-left: unset !important;
    padding-right: unset !important;
    object-fit: fill;
  }
  .postContainer .innerPostContent p p {
    text-align: left !important;
  }
}
.input-group .form-control {
  color: black !important;
}
.blogArticleLink {
  color: black;
  cursor: pointer !important;
}
/* @media (max-width: 767px){
    .scrolls-space {
        border-bottom: unset;
    }
    .footerCopy{
        display: flex;
        justify-content: center;
    }
    .Leadercontent{
        width: unset;
        margin-bottom: 9px;
        margin-right: 0px;
        margin-left: unset;
    }
    .row{
        flex-direction: column;
    }
    .postContainer{
        width: 100%;
    }
    .postContainer .innerPostContent img{
    width: 100%;
    height: 220px;
    object-fit: fill;
    }
    .postContainer .innerPostContent p p{
        text-align: left !important;
    }
} */
