.tick {
  height: 300px;
  width: 350px;
  margin-bottom: 25px;
}

.thanks {
  margin-top: 100px;
  margin-bottom: 15px;
  font-size: 50px;
}

.thanks_p {
  font-size: 25px;
  margin-bottom: 50px;
}

.thanks_div {
  text-align: center;
}
